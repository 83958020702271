import React from "react";
import PropTypes from "prop-types";
import FeatureCard from "wonderbly-components/lib/FeatureCard";
import RichText from "src/client/js/view/components/Prismic/RichText";
import SectionHeading from "src/client/js/view/components/SectionHeading";
import { localiseUrlII } from "src/common/utils/format-localised-url";
import { useCountry } from "src/client/js/view/hooks";

import "./FeatureCards.scss";

const FeatureCards = ({ featureCardGroup }) => {
  const country = useCountry();

  return (
    <section className="FeatureCards">
      {featureCardGroup.groupTitle && (
        <SectionHeading tag="h2" textAlign="left">
          {featureCardGroup.groupTitle}
        </SectionHeading>
      )}
      <div className="container-guttered">
        <div className="FeatureCards__row">
          {featureCardGroup.featureCard?.map((card, idx) => {
            return (
              <FeatureCard
                key={idx}
                href={localiseUrlII(country, card.cardHref)}
                backgroundImage={card.backgroundImage}
                content={
                  <RichText
                    fields={card.cardContent}
                    color={card.cardTextColour}
                  />
                }
                textColour={card.cardTextColour}
                ctaText={card.ctaText}
                ctaColour={card.ctaColour}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

FeatureCards.propTypes = {
  featureCardGroup: PropTypes.shape({
    groupTitle: PropTypes.string,
    featureCard: PropTypes.arrayOf(
      PropTypes.shape({
        backgroundImage: PropTypes.object,
        cardContent: PropTypes.array.isRequired,
        cardTextColour: PropTypes.string,
        ctaText: PropTypes.string,
        ctaColour: PropTypes.string,
        cardHref: PropTypes.string.isRequired,
      })
    ),
  }),
};

export default FeatureCards;
